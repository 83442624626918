export const userInitials = (user) => {
  return `${user?.firstName?.[0]?.toUpperCase() || ''}${user?.lastName?.[0]?.toUpperCase() || ''}`
}

export const userMustFinishOnboarding = (user) => {
  const hasFinishedGexQuiz =
    user?.account?.conditionalDisplayAttributes?.hasFinishedGexOnboardingQuiz

  const onboardingProgress = user?.account?.conditionalDisplayAttributes?.onboardingProgress
  const mustFinishOnboardingQuiz =
    user?.account?.conditionalDisplayAttributes?.mustFinishOnboardingQuiz

  // If user account is inactive / current user does not exist
  if (!user?.account?.active) {
    return false
  }
  // If an old user has left the old quiz incomplete, in this case they will always
  // need to complete the GEX quiz
  else if ((onboardingProgress && onboardingProgress > 0) || mustFinishOnboardingQuiz) {
    return !hasFinishedGexQuiz
  }
  // If old user has finished the old quiz they will have the option
  // later to complete new smaller GEX quiz
  else if (!mustFinishOnboardingQuiz || onboardingProgress === 0) {
    return false
  }
  // New user not relying on old quiz/onboarding completion flags
  else if (!onboardingProgress) {
    return !hasFinishedGexQuiz
  } else {
    return true
  }
}
