import { ObeFooter } from './ObeFooter'
import { SiteFooter } from './SiteFooter'
import { Switch } from '../Switch'

export const Footer = () =>
  React.createElement(Switch, {
    config: [
      {
        Component: <ObeFooter />,
        paths: [
          '/affiliate/:code',
          '/forgot-password',
          '/login',
          '/partner/:code',
          '/reset-password/:token',
          '/comeback',
        ],
      },
      {
        Component: null,
        paths: ['/onboarding/quiz', '/workoutparty/:id'],
      },
    ],
    fallbackComponent: <SiteFooter />,
  })
