import { TransitionGroup, CSSTransition } from 'react-transition-group'

export const Slide = ({
  className = '',
  direction = 'left',
  children,
  transitionKey,
  timeout = 1000,
  ...props
}) => {
  return (
    <TransitionGroup component={null}>
      <CSSTransition key={transitionKey} classNames={direction} timeout={timeout} {...props}>
        <Styles.Container className={className} timeout={timeout}>
          {children}
        </Styles.Container>
      </CSSTransition>
    </TransitionGroup>
  )
}

const Styles = {
  Container: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;

    &.left-enter {
      transform: translateX(100%);
      opacity: 0.01;
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout * 2.5}ms`};
    }

    &.left-enter-active {
      opacity: 1;
      transform: none;
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout * 2.5}ms`};
    }

    &.left-exit {
      opacity: 1;
      transform: none;
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout * 2.5}ms`};
    }

    &.left-exit-active {
      opacity: 0.01;
      transform: translateX(-100%);
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout}ms`};
    }

    &.right-enter {
      transform: translateX(-100%);
      opacity: 0.01;
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout * 2.5}ms`};
    }

    &.right-enter-active {
      opacity: 1;
      transform: none;
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout * 2.5}ms`};
    }

    &.right-exit {
      opacity: 1;
      transform: none;
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout * 2.5}ms`};
    }

    &.right-exit-active {
      opacity: 0.01;
      transform: translateX(100%);
      transition: ${({ timeout }) => `transform ${timeout}ms, opacity ${timeout}ms`};
    }
  `,
}
