import { twMerge } from 'tailwind-merge'

import { TypographyBase, type TypographyBaseProps } from 'src/components/Typography/BaseTypography'

export const One = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'leading-obe-p-130 text-[0.625rem] font-light sm:text-[0.688rem] lg:text-[0.75rem]',
      className,
    )}
    {...props}
  />
)
