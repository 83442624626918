import { Redirect } from './Redirect'
import { useRouter } from 'src/hooks/useRouter'
import { useUser } from 'src/hooks/useUser'
import { userMustFinishOnboarding } from 'src/utils/userUtils'

interface OnboardingBouncerProps {
  allowedRoutes?: string[]
  children: React.ReactElement
}

export const OnboardingBouncer = ({
  allowedRoutes = ['/logout', '/plans'],
  children,
}: OnboardingBouncerProps) => {
  const { currentUser, isMember } = useUser() || {}
  const { pathname } = useRouter()

  const mustFinishOnboarding = userMustFinishOnboarding(currentUser)

  // Ensure that core only users that finished onboarding aren't redirected to `/membership/purchase`
  const shouldRedirectActiveUser =
    isMember && !mustFinishOnboarding && pathname === '/membership/purchase'

  const redirectRoute = shouldRedirectActiveUser ? '/home' : '/onboarding/welcome'

  if (shouldRedirectActiveUser) return <Redirect to={redirectRoute} />

  return mustFinishOnboarding && !allowedRoutes.includes(pathname) ? (
    <Redirect to={redirectRoute} />
  ) : (
    children
  )
}
