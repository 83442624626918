import { useMemo } from 'react'

import { links, type NavLinkType } from 'src/components/App/Header/navigation'
import useUser from 'src/hooks/useUser'

export function useFilteredMenuItems(menuItems: NavLinkType[]) {
  const user = useUser()

  const filteredMenuItems = useMemo(() => {
    return user.isNonbillable || user.hasFreemiumEnabled
      ? menuItems.filter((item) => item.to !== links.manageSubscriptions.to)
      : menuItems
  }, [menuItems, user.hasFreemiumEnabled, user.isNonbillable])

  return filteredMenuItems
}
