import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'

import { breakpointMedia } from 'src/styles'

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ children, ...props }, ref) => {
  const StyledRoot = styled(NavigationMenuPrimitive.Root)`
    display: flex;
    position: relative;
    z-index: 10;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    max-width: max-content;
  `

  return (
    <StyledRoot ref={ref} {...props}>
      {children}
      <NavigationMenuViewport />
    </StyledRoot>
  )
})
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ ...props }, ref) => {
  const StyledList = styled(NavigationMenuPrimitive.List)`
    display: flex;
    margin: 0;
    padding: 0;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    list-style-type: none;
  `
  return <StyledList ref={ref} {...props} />
})
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName

const NavigationMenuItem = NavigationMenuPrimitive.Item

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ children, ...props }, ref) => {
  const StyledTrigger = styled(NavigationMenuPrimitive.Trigger)``
  return (
    <StyledTrigger ref={ref} {...props}>
      {children}
    </StyledTrigger>
  )
})
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ ...props }, ref) => {
  const StyledContent = styled(NavigationMenuPrimitive.Content)`
    overflow: hidden;
    width: max-content;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    border: 1px solid var(--neutrals400, #e3e3e4);
    border-radius: 0.25rem;
    border-width: 1px;
    background: var(--neutrals100, #fff);
    box-shadow: 4px 20px 40px 0px rgba(0, 0, 0, 0.03);

    ${breakpointMedia.largeAndUp} {
      width: max-content;
    }
  `

  return <StyledContent ref={ref} {...props} />
})
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName

const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Link>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ ...props }, ref) => {
  const StyledLink = styled(NavigationMenuPrimitive.Link)`
    display: flex;
    position: relative;
    padding: 0.875rem;
    padding-right: 1.5rem;
    align-items: center;
    line-height: 1.25rem;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    color: var(--neutrals800);
    cursor: pointer;
    user-select: none;
    :hover {
      background-color: var(--neutrals300);
      text-decoration: none;
      p {
        // This serves as a font-weight of 400 and doesn't change the container size.
        // If you wanted to use font-weight, you would need to set the minimum width of the container to something.
        text-shadow: 0px 0px 1px var(--neutrals800);
      }
    }
    :not(:last-of-type) {
      border-bottom: 1px solid var(--neutrals400);
    }
  `
  return <StyledLink ref={ref} {...props} />
})

const NavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ ...props }, ref) => {
  const StyledViewportWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    top: 100%;
    justify-content: center;
  `

  const StyledViewport = styled(NavigationMenuPrimitive.Viewport)`
    overflow: hidden;
    position: relative;
    margin-top: 0.5rem;
    border-radius: 0.25rem;
    border-width: 1px;
    width: 100%;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    height: var(--radix-navigation-menu-viewport-height);

    ${breakpointMedia.largeAndUp} {
      width: var(--radix-navigation-menu-viewport-width);
    }
  `

  return (
    <StyledViewportWrapper>
      <StyledViewport ref={ref} {...props} />
    </StyledViewportWrapper>
  )
})
NavigationMenuViewport.displayName = NavigationMenuPrimitive.Viewport.displayName

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    style={{
      display: 'flex',
      overflow: 'hidden',
      top: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: '0.375rem',
    }}
    {...props}
  >
    <div className="bg-border relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm shadow-md" />
  </NavigationMenuPrimitive.Indicator>
))
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName

export {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
}
