'use client'

import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useState, type FormEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import { Search } from 'src/components/Icon/SearchNew'
import { X } from 'src/components/Icon/X'

export const SearchBar = () => {
  const pathname = usePathname()
  const params = useSearchParams()
  const router = useRouter()
  const [search, setSearch] = useState(params.get('search') || '')

  const searchVideos = useCallback(
    (newSearch: string) => {
      const url = new URL('/videos', window.origin)
      const searchParams = new URLSearchParams(params)
      url.search = searchParams.toString()
      if (newSearch) {
        url.searchParams.set('search', newSearch)
        url.searchParams.set('sort', 'default')
      } else {
        url.searchParams.delete('search')
        url.searchParams.set('sort', 'newest')
      }
      router.push(url.href)
    },
    [params, router],
  )

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      searchVideos(search)
    },
    [search, searchVideos],
  )

  const clear = useCallback(() => {
    setSearch('')
    if (pathname === '/videos') {
      searchVideos('')
    }
  }, [pathname, searchVideos])

  return (
    <div
      className="hidden flex-shrink basis-[442px] rounded-[100px] bg-white px-[20px] py-3.5 shadow-obe-neutral-xxs
        lg:block"
    >
      <form onSubmit={onSubmit}>
        <div className="flex items-center gap-2.5">
          <Search className="h-[18px] w-[18px] fill-[#4D4C4F]" />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={(e) => (e.currentTarget.placeholder = '')}
            onBlur={(e) => (e.currentTarget.placeholder = 'Search classes')}
            placeholder='Search classes'
            className="w-full min-w-6 flex-1 font-centra font-normal leading-[150%] text-black placeholder-obe-neutral-600
              outline-none"
          />
          <X
            onClick={() => clear()}
            className={twMerge('h-6 w-6 stroke-black opacity-100 transition-opacity duration-150', [
              !search.length && 'opacity-0',
            ])}
          />
        </div>
        <button type='submit' className="hidden" />
      </form>
    </div>
  )
}
