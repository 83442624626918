import { twMerge } from 'tailwind-merge'

import { TypographyBase, type TypographyBaseProps } from 'src/components/Typography/BaseTypography'

export const One = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'leading-obe-p-150 text-[1.25rem] font-light sm:text-[1.3125rem] lg:text-[1.5rem]',
      className,
    )}
    {...props}
  />
)

export const OneBold = ({ className, ...props }: TypographyBaseProps) => (
  <One className={twMerge('font-medium', className)} {...props} />
)

export const OneLink = ({ className, ...props }: TypographyBaseProps) => (
  <One className={twMerge('font-medium underline', className)} {...props} />
)

export const Two = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'leading-obe-p-150 text-[1.125rem] font-light sm:text-[1.1875rem] lg:text-[1.375rem]',
      className,
    )}
    {...props}
  />
)

export const TwoBold = ({ className, ...props }: TypographyBaseProps) => (
  <Two className={twMerge('font-normal', className)} {...props} />
)

export const TwoLink = ({ className, ...props }: TypographyBaseProps) => (
  <Two className={twMerge('font-normal underline', className)} {...props} />
)

export const Three = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'leading-obe-p-150 text-[1rem] font-light sm:text-[1.0625rem] lg:text-[1.1875rem]',
      className,
    )}
    {...props}
  />
)

export const ThreeBold = ({ className, ...props }: TypographyBaseProps) => (
  <Three className={twMerge('font-normal', className)} {...props} />
)

export const ThreeLink = ({ className, ...props }: TypographyBaseProps) => (
  <Three className={twMerge('font-normal underline', className)} {...props} />
)

export const Four = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'leading-obe-p-150 text-[0.875rem] font-light sm:text-[0.9375rem] lg:text-[1rem]',
      className,
    )}
    {...props}
  />
)

export const FourBold = ({ className, ...props }: TypographyBaseProps) => (
  <Four className={twMerge('font-normal', className)} {...props} />
)

export const FourLink = ({ className, ...props }: TypographyBaseProps) => (
  <Four className={twMerge('font-normal underline', className)} {...props} />
)

export const Five = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'leading-obe-p-150 text-[0.75rem] font-light sm:text-[0.8125rem] lg:text-[0.875rem]',
      className,
    )}
    {...props}
  />
)

export const FiveBold = ({ className, ...props }: TypographyBaseProps) => (
  <Five className={twMerge('font-normal', className)} {...props} />
)

export const FiveLink = ({ className, ...props }: TypographyBaseProps) => (
  <Five className={twMerge('font-normal underline', className)} {...props} />
)
