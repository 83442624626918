'use client'

import { Spinner } from 'src/components/Spinner'

export const Loader = ({ children, isLoading, noSpinner = false, className = '', ...display }) =>
  isLoading
    ? React.createElement(
        Container,
        {
          ...display,
          className,
        },
        !noSpinner ? <Spinner {...display} /> : null,
      )
    : children

const Container = styled.div`
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
  `}
  ${({ aspectRatio }) =>
    aspectRatio &&
    `
    padding-top: ${aspectRatio}%;
    position: relative;
    > * {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `}
`
