import { Image } from 'src/components/Display/Image'
import { Link } from 'src/components/Link'

export const ObeHeader = () => {
  return (
    <div className="flex items-center justify-center py-6">
      <Link action='Returned to Marketing Page' to='/'>
        <Image
          src='/obe-logo-color.png'
          alt='obé logo'
          width={750}
          height={312}
          className="h-8 w-[82px]"
        />
      </Link>
    </div>
  )
}
