import type { SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const Bookmark = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 16 23'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    className={twMerge('w-4 stroke-black stroke-[1.5px]', className)}
    {...props}
  >
    <path d='M1 21.191V1.5C1 1.22386 1.22386 1 1.5 1H14C14.2761 1 14.5 1.22386 14.5 1.5V21.1629C14.5 21.5412 14.0961 21.7825 13.763 21.6031L8.22852 18.623C8.08525 18.5459 7.9134 18.5433 7.76786 18.6161L1.72361 21.6382C1.39116 21.8044 1 21.5627 1 21.191Z' />
  </svg>
)
