import { useFilteredMenuItems } from 'src/components/App/Header/MainHeader/useFilteredMenuItems'
import { links, type NavLinkType } from 'src/components/App/Header/navigation'
import { Drawer } from 'src/components/Display/Drawer/Drawer'
import { Link } from 'src/components/Link'
import { Typography } from 'src/components/Typography'

const menuItems: NavLinkType[] = [
  links.profile,
  links.classes,
  links.programs,
  links.collections,
  links.classStack,
  links.courseworks,
  links.settings,
  links.manageSubscriptions,
  links.changePassword,
  { ...links.guestPass, label: 'Send guest pass' },
  links.logout,
]

const tracking = { context: 'nav' }

export const MainHeaderDrawer = () => {
  const filteredMenuItems = useFilteredMenuItems(menuItems)

  return (
    <Drawer
      id='main-header-drawer'
      content={
        <Drawer.Content className="top-[var(--main-header-mobile-height)] lg:top-[var(--main-header-height)]">
          <Drawer.Overlay className="top-[var(--main-header-mobile-height)] lg:top-[var(--main-header-height)]" />
          <ul className="flex flex-col gap-5">
            {filteredMenuItems.map(({ action, label, to }) => {
              return (
                <li key={to}>
                  <Link
                    action={action}
                    synthetic={false}
                    to={to}
                    tracking={tracking}
                    className="group relative mr-6 inline-block text-left text-black hover:text-primary-400 hover:no-underline
                      focus:outline-none"
                  >
                    <Typography.Body3 className="relative w-fit overflow-hidden whitespace-nowrap group-hover:text-primary-400">
                      {label}
                    </Typography.Body3>
                  </Link>
                </li>
              )
            })}
          </ul>
        </Drawer.Content>
      }
    />
  )
}
