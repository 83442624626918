import { usePathname } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

import { links } from 'src/components/App/Header/navigation'
import { Bookmark } from 'src/components/Icon/Bookmark'
import { Link } from 'src/components/Link'
import useUser from 'src/hooks/useUser'

export const BookmarkLink = () => {
  const { username } = useUser()
  const pathname = usePathname()

  const isBookmarkIconActive =
    pathname === links.bookmarks.to || pathname === `/${username}${links.bookmarks.to}`

  return (
    <Link to={links.bookmarks.to}>
      <Bookmark
        className={twMerge('h-6 w-6 hover:stroke-primary-500', [
          isBookmarkIconActive &&
            'fill-primary-400 stroke-primary-400 hover:fill-primary-500 hover:stroke-primary-500',
        ])}
      />
    </Link>
  )
}
