import { useMemo, type ReactElement } from 'react'

import { LandingHeader } from 'src/components/App/Header/Landing/LandingHeader'
import { MainHeader } from 'src/components/App/Header/MainHeader/MainHeader'
import { ObeHeader } from 'src/components/App/Header/ObeHeader'
import { OnePageHeader } from 'src/components/App/Header/OnePageHeader'
import { TemplateHeader } from 'src/components/App/Header/TemplateHeader'
import { Switch } from 'src/components/App/Switch'
import { useUser } from 'src/hooks/useUser'

interface Props {
  logo?: string
}

export const Header = ({ logo }: Props) => {
  const { isMember } = useUser()

  const switchConfig = useMemo(() => {
    return buildSwitchConfig(logo)
  }, [logo])

  return (
    <Switch
      config={switchConfig}
      fallbackComponent={isMember ? <MainHeader /> : <LandingHeader />}
    />
  )
}

function buildSwitchConfig(
  logo: string | undefined,
): { Component: ReactElement | null; paths: string[] }[] {
  return [
    {
      Component: <ObeHeader />,
      paths: [
        '/forgot-password',
        '/login',
        '/onboarding/subscription',
        '/partner/:code',
        '/reset-password/:token',
        '/comeback',
        '/users/personal-training-details',
      ],
    },
    {
      Component: <OnePageHeader />,
      paths: ['/users/sign_up/new', '/users/sign_up/levelup', '/users/sign_up/new', '/obe-preview'],
    },
    {
      Component: <TemplateHeader logo={logo} />,
      paths: [
        '/aa',
        '/showfields',
        '/student',
        '/uberone',
        '/users/sign_up/athleta/core',
        '/users/sign_up/athleta/icon',
        '/users/sign_up/athleta/iconreward',
        '/users/sign_up/athleta/enthusiast',
        '/users/sign_up/athleta/enthusiastreward',
      ],
    },
    {
      Component: null,
      paths: ['/affiliate/:code', '/onboarding/quiz', '/workoutparty/:id'],
    },
  ]
}
