import type { DetailedHTMLProps, HTMLAttributes } from 'react'

import { Typography } from 'src/components/Typography'
import { twMerge } from 'tailwind-merge'

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  as: keyof typeof Typography
}

export const GradientTypography = ({ as, className, ...props }: Props) => {
  const TypographyComponent = Typography[as]

  return (
    <TypographyComponent
      className={twMerge('bg-gradient-1000 bg-clip-text text-transparent', className)}
      {...props}
    />
  )
}
