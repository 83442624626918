'use client'

import { usePathname } from 'next/navigation'

import { links } from 'src/components/App/Header/navigation'
import { ClassStackIcon } from 'src/components/Icon/ClassStack'
import { Link } from 'src/components/Link'
import { twMerge } from 'tailwind-merge'

export const ClassStackLink = () => {
  const pathname = usePathname()

  const isActive = pathname === links.classStack.to

  return (
    <Link to={links.classStack.to}>
      <ClassStackIcon
        className={twMerge('hover:stroke-primary-500', [isActive && 'stroke-primary-400'])}
      />
    </Link>
  )
}
