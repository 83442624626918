import * as Display from 'src/components/Typography/Display/DisplayTypography'
import * as Heading from 'src/components/Typography/Heading/HeadingTypography'
import * as Body from 'src/components/Typography/Body/BodyTypography'
import * as Caption from 'src/components/Typography/Caption/CaptionTypography'
import { GradientTypography } from 'src/components/Typography/GradientTypography'

export const Typography = {
  Display1: Display.One,
  Display2: Display.Two,
  Display3: Display.Three,
  Display4: Display.Four,
  Heading1: Heading.One,
  Heading2: Heading.Two,
  Heading3: Heading.Three,
  Heading4: Heading.Four,
  Heading5: Heading.Five,
  Body1: Body.One,
  Body2: Body.Two,
  Body3: Body.Three,
  Body4: Body.Four,
  Body5: Body.Five,
  Body1Link: Body.OneLink,
  Body2Link: Body.TwoLink,
  Body3Link: Body.ThreeLink,
  Body4Link: Body.FourLink,
  Body5Link: Body.FiveLink,
  Body1Bold: Body.OneBold,
  Body2Bold: Body.TwoBold,
  Body3Bold: Body.ThreeBold,
  Body4Bold: Body.FourBold,
  Body5Bold: Body.FiveBold,
  Caption1: Caption.One,
}

export { GradientTypography }
