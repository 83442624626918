import { usePathname } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

import type { NavLinkType } from 'src/components/App/Header/navigation'
import { Link, type LinkProps } from 'src/components/Link'
import { Typography } from 'src/components/Typography'

interface Props {
  link: NavLinkType
  linkProps?: Omit<LinkProps, 'to'>
}

export const NavLink = ({ link, linkProps }: Props) => {
  const pathname = usePathname()

  return (
    <Link
      className="whitespace-nowrap hover:no-underline focus:outline-none"
      action={link.action}
      to={link.to}
      synthetic={link.synthetic}
      tracking={{ context: 'nav' }}
      {...linkProps}
    >
      <Typography.Body3
        className={twMerge('hover:text-primary-500', [
          link.to && pathname.includes(link.to) && 'text-primary-400',
        ])}
      >
        {link.label}
      </Typography.Body3>
    </Link>
  )
}
