import { twMerge } from 'tailwind-merge'

import { TypographyBase, type TypographyBaseProps } from 'src/components/Typography/BaseTypography'

export const One = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'text-[2rem] leading-obe-p-110 sm:text-[2.125rem] lg:text-[2.75rem] lg:leading-obe-p-130',
      className,
    )}
    {...props}
  />
)

export const Two = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'text-[2rem] font-light leading-obe-p-110 sm:text-[2.125rem] lg:text-[2.75rem] lg:leading-obe-p-130',
      className,
    )}
    {...props}
  />
)

export const Three = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'text-[1.75rem] leading-obe-p-110 sm:text-[1.875rem] lg:text-[2.125rem] lg:leading-obe-p-130',
      className,
    )}
    {...props}
  />
)

export const Four = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      `text-[1.75rem] font-light leading-obe-p-110 sm:text-[1.875rem] lg:text-[2.125rem]
      lg:leading-obe-p-130`,
      className,
    )}
    {...props}
  />
)

export const Five = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'text-[1.5rem] leading-obe-p-110 lg:text-[1.75rem] lg:leading-obe-p-130',
      className,
    )}
    {...props}
  />
)
