import { twMerge } from 'tailwind-merge'

import { TypographyBase, type TypographyBaseProps } from 'src/components/Typography/BaseTypography'

export const One = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      `leading-obe-p-110 lg:leading-obe-p-115 text-[2.75rem] font-semibold sm:text-[3.25rem]
      lg:text-[5.125rem]`,
      className,
    )}
    {...props}
  />
)

export const Two = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      `leading-obe-p-110 lg:leading-obe-p-115 text-[2.75rem] font-light sm:text-[3.25rem]
      lg:text-[5.125rem]`,
      className,
    )}
    {...props}
  />
)

export const Three = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      `leading-obe-p-110 lg:leading-obe-p-115 text-[2.5rem] font-semibold sm:text-[2.75rem]
      lg:text-[3.25rem]`,
      className,
    )}
    {...props}
  />
)

export const Four = ({ className, ...props }: TypographyBaseProps) => (
  <TypographyBase
    className={twMerge(
      'leading-obe-p-110 lg:leading-obe-p-115 text-[2.5rem] font-light sm:text-[2.75rem] lg:text-[3.25rem]',
      className,
    )}
    {...props}
  />
)
