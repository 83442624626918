import type { SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const EmptyAvatar = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='52'
    height='52'
    viewBox='0 0 52 52'
    className={twMerge('fill-white', className)}
    {...props}
  >
    <circle cx='26' cy='26' r='25.5' fill='white' stroke='black' />
    <path
      d='M29.7711 23.1046C30.7713 22.1044 31.3332 20.7478 31.3332 19.3333C31.3332 17.9188 30.7713 16.5623 29.7711 15.5621C28.7709 14.5619 27.4143 14 25.9998 14C24.5853 14 23.2288 14.5619 22.2286 15.5621C21.2284 16.5623 20.6665 17.9188 20.6665 19.3333C20.6665 20.7478 21.2284 22.1044 22.2286 23.1046C23.2288 24.1048 24.5853 24.6667 25.9998 24.6667C27.4143 24.6667 28.7709 24.1048 29.7711 23.1046Z'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.4002 31.4003C21.1505 29.65 23.5245 28.6667 25.9998 28.6667C28.4752 28.6667 30.8492 29.65 32.5995 31.4003C34.3498 33.1507 35.3332 35.5246 35.3332 38H16.6665C16.6665 35.5246 17.6498 33.1507 19.4002 31.4003Z'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
