import { useRouter } from 'src/hooks/useRouter'

export const Redirect = ({ to, replace = false }) => {
  const router = useRouter()

  React.useEffect(() => {
    replace ? router.replace(to) : router.push(to)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
