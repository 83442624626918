import { forwardRef, type DetailedHTMLProps, type HTMLAttributes } from 'react'
import { EmptyAvatar } from 'src/components/Icon/EmptyAvatar'

import useUser from 'src/hooks/useUser'
import { avatarLoader } from 'src/utils/image'
import { userInitials } from 'src/utils/userUtils'

export const UserAvatar = forwardRef<
  HTMLButtonElement,
  DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>((props, ref) => {
  const { isLoading, currentUser } = useUser()
  const initials = userInitials(currentUser?.profile)

  return isLoading ? (
    <AvatarSkeleton />
  ) : (
    <AvatarContainer>
      {currentUser && currentUser.profile.avatarUrl ? (
        <button ref={ref} {...props} className="focus:outline-none">
          <img
            className="-ml-4 size-12 rounded-full first:-ml-0"
            src={avatarLoader(currentUser.profile.avatarUrl)}
            alt='avatar picture'
          />
        </button>
      ) : initials ? (
        <UserInitials ref={ref} {...props}>
          {initials}
        </UserInitials>
      ) : (
        <button ref={ref} {...props} className="focus:outline-none">
          <EmptyAvatar />
        </button>
      )}
    </AvatarContainer>
  )
})

const AvatarSkeleton = styled.div`
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 50%;
  background-color: var(--neutrals300);
`

const AvatarContainer = styled.div`
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 50%;
`

const UserInitials = styled.button`
  background-color: white;
  width: var(--avatar-size);
  height: var(--avatar-size);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1.5px solid var(--nearBlack);
  border-radius: 50%;
  :focus {
    outline: none;
  }
`
