import { NavLink } from 'src/components/App/Header/Nav/NavLink'
import { links, type NavLinkType } from 'src/components/App/Header/navigation'

export const NavLinks = () => {
  const navLinks: NavLinkType[] = [
    links.classes,
    links.programs,
    links.collections,
    links.courseworks,
  ]

  return (
    <nav className="hidden h-[var(--main-header-height)] items-center gap-6 lg:flex">
      {navLinks.map((link) => (
        <NavLink key={link.to} link={link} />
      ))}
    </nav>
  )
}
