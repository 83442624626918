'use client'

import { useFilteredMenuItems } from 'src/components/App/Header/MainHeader/useFilteredMenuItems'
import type { NavLinkType } from 'src/components/App/Header/navigation'
import { links } from 'src/components/App/Header/navigation'
import { UserAvatar } from 'src/components/App/Header/UserMenu/UserAvatar'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from 'src/components/Display/NavigationMenu/NavigationMenu'
import { Typography } from 'src/components/Typography'

export const UserMenu = () => {
  const filteredMenuItems = useFilteredMenuItems(menuItems)

  return (
    <NavigationMenu delayDuration={100}>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger asChild>
            <UserAvatar />
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            {filteredMenuItems.map((item) => (
              <NavigationMenuLink key={item.to} href={item.to}>
                <Typography.Body4>{item.label}</Typography.Body4>
              </NavigationMenuLink>
            ))}
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const menuItems: NavLinkType[] = [
  links.profile,
  links.settings,
  links.manageSubscriptions,
  links.changePassword,
  links.logout,
]
