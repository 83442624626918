import { Image } from 'src/components/Display/Image'
import { Link } from 'src/components/Link'
import { useResponsive } from 'src/hooks/useResponsive'
import { breakpoint, mixin } from 'src/styles'

export const OnePageHeader = () => {
  const { isLargeAndUp } = useResponsive()

  return (
    <Container>
      <Left>
        <Logo action='Returned to Marketing Page' to='/'>
          <Image src='/obe-logo-color.png' width={750} height={312} alt='logo' />
        </Logo>
      </Left>
      <Login>
        <>
          {isLargeAndUp && 'Already have an account?'} <Link to='/login'>log in</Link>
        </>
      </Login>
    </Container>
  )
}

const Container = styled.div`
  ${mixin.flexSpaceBetween}
  align-items: flex-end;
  padding: 20px;
  background-color: '#f8f8f8';

  ${breakpoint.largeAndUp} {
    border: 1px solid var(--neutrals400);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    padding: 20px 40px;
  }
`

const Left = styled.div`
  padding-left: 33%;
  flex: 1 1 66%;
`

const Logo = styled(Link)`
  display: block;
  margin: 0 auto;
  width: 70px;

  ${breakpoint.smallAndDown} {
    width: 50px;
  }
`

const Login = styled.div`
  color: #4d4c4f;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  flex: 1 1 33%;

  a {
    color: var(--persianBlue);
    font-weight: 600;
    text-transform: uppercase;
  }
`
