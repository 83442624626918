import { Fragment, useMemo, type ReactElement } from 'react'

import { useRouter } from 'src/hooks/useRouter'

interface Props {
  config: {
    Component: ReactElement | null
    paths: string[]
  }[]
  fallbackComponent?: ReactElement
}

export const Switch = ({ config, fallbackComponent = <Fragment /> }: Props) => {
  const router = useRouter()
  const Component = useMemo(
    () =>
      config?.find((configItem) => configItem?.paths?.includes(router.pathname))?.Component ||
      fallbackComponent,
    [config, fallbackComponent, router.pathname],
  )

  return Component
}
