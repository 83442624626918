import { Link } from 'src/components/Link'
import { useResponsive } from 'src/hooks/useResponsive'
import { breakpoint } from 'src/styles'

interface Props {
  logo?: string
}

export const TemplateHeader = ({ logo }: Props) => {
  const { isLargeAndUp } = useResponsive()

  return (
    <Container>
      <Logo>
        <Image src={logo} alt='logo' />
      </Logo>
      <Login>
        {isLargeAndUp && 'Already have an account?'} <Link to='/login'>log in</Link>
      </Login>
    </Container>
  )
}

const Container = styled.div`
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  border-bottom: 1px solid var(--grey);

  ${breakpoint.largeAndUp} {
    height: 80px;
  }
`

const Logo = styled.div`
  align-items: center;
`

const Image = styled.img`
  height: 28px;

  ${breakpoint.largeAndUp} {
    height: 40px;
  }
`

const Login = styled.div`
  position: absolute;
  right: 17px;
  color: #979797;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  a {
    color: blue;
    font-weight: 600;
    text-transform: uppercase;
  }

  ${breakpoint.largeAndUp} {
    right: 40px;
  }
`
