import type { SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const ClassStackIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={twMerge(['aspect-square w-6 stroke-black', className])}
    {...props}
  >
    <path
      d='M20.8688 1.9126H6.42376C5.85007 1.9126 5.38501 2.37766 5.38501 2.95135V17.3963C5.38501 17.97 5.85007 18.4351 6.42376 18.4351H20.8688C21.4424 18.4351 21.9075 17.97 21.9075 17.3963V2.95135C21.9075 2.37766 21.4424 1.9126 20.8688 1.9126Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.2653 22.0575H3.87409C2.79034 22.0575 1.91284 21.18 1.91284 20.0963V6.65625'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.6604 10.1851H17.4042'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5305 6.31128V14.055'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
